import React, {Component} from "react";
import './HomeBanner.css'

class HomeBanner extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            loading: false,
        }
        this.state = {...this.initState}
    }

    render() {
        return (
            <React.Fragment>
                <div style={{
                    backgroundImage: `url('${this.props.backgroundImage}')`,
                    backgroundAttachment: `${this.props.backgroundAttachment}`,
                }} className={this.props.imageCode}>
                </div>
            </React.Fragment>
        );
    }
}

export default HomeBanner;
