import React, {Component} from "react";
import {Buffer} from "buffer";
import {TrackStates} from "./types/TrackTypes";
import {Link} from "react-router-dom";
import {Header} from "antd/es/layout/layout";
import {withRouter} from "../../hoc/withRouter";
import './Track.css';
import Layout from "antd/lib/layout";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Button from "antd/lib/button";
import Typography from "antd/lib/typography";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Modal from "antd/lib/modal";
import {trackApiServices} from "../../services/track/TrackApiService";
import Timeline from "antd/lib/timeline";
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import moment from "moment";
import {trackLogServices} from "../../services/track/TrackLogService";
import Spin from "antd/lib/spin";
import {offerApiServices} from "../../services/offer/OfferApiService";
import {Divider} from "antd/es";

class Track extends Component<any, TrackStates> {
    private readonly initState!: TrackStates;

    constructor(props: any) {
        super(props);
        document.title = `Amartachery | Track`;

        this.initState = {
            offerCode: "",
            phoneNumber: "",
            fetchTrack: false,
            finishedFetchTrack: false,
            dataTrack: [],
            spkName: "",
            spkPhoneNumber: "",
            spkVariant: "",
            spkVariantColor: "",
        }
        this.state = {...this.initState}
    }

    onFieldChange = <T extends keyof Pick<any, "phoneNumber" | "offerCode">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "phoneNumber":
                currentState.phoneNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "offerCode":
                currentState.offerCode = value;
                break;
        }

        if (target === 'phoneNumber') {
            if (currentState.phoneNumber.length > 1) {
                if (currentState.phoneNumber.substring(0, 2) !== '08') {
                    currentState.phoneNumber = "";
                }
            }
        }

        this.setState({
            ...currentState,
        });
    }

    onClickClose = async () => {
        this.setState({
            offerCode: "",
            phoneNumber: "",
            fetchTrack: false,
            finishedFetchTrack: false,
            dataTrack: [],
        });
    }

    onClickSearch = async () => {
        if (!this.state.offerCode || !this.state.phoneNumber) {
            Modal.error({
                title: 'Proses Gagal',
                content: "Mohon Lengkapi Data"
            });
            return false;
        }
        await this.trackSpk({source: "amartachery.com"});
    }

    trackSpk = async (params: any) => {
        await this.promisedSetState({fetchTrack: true, spkName: "", spkPhoneNumber: ""});
        try {
            const successData = await offerApiServices.getPublicOffer({offerCode: this.state.offerCode});
            this.setState({
                spkName: successData?.data?.data?.id_card_owner?.full_name,
                spkPhoneNumber: successData?.data?.data?.contacts?.phone_number_owner,
                spkVariant: successData?.data?.data?.vehicle?.variant_name,
                spkVariantColor: successData?.data?.data?.vehicle?.color_name,
            });

        } catch (e) {
        }

        try {
            const successData = await trackApiServices.getTrack({type: "customer", offerCode: this.state.offerCode, phoneNumber: this.state.phoneNumber});
            const trackData: any = [];
            for (const item of successData.data.data) {
                const myDate = new Date(item.time);
                const withOffset = myDate.getTime();
                const dateString = moment.unix(withOffset / 1000).format("DD MMM YYYY, H:mm");
                trackData.push({
                    time: dateString + " WIB",
                    title: item.description
                });
            }
            this.setState({
                fetchTrack: false,
                finishedFetchTrack: true,
                dataTrack: trackData
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Data SPK Tidak Ditemukan'
            });
            await this.promisedSetState({fetchTrack: false});
            await this.onClickClose();
        }
    }

    async componentDidMount() {
        await trackLogServices.sendTrackLog({eventName: `track page`, eventSourceUrl: window.location.href, customData: null});
        const queryParams = new URLSearchParams(window.location.search);
        const utmSource = queryParams.get('utm_source');
        const idQuery: any = queryParams.get('id');

        if (utmSource) {
            const spkNumber = Buffer.from(idQuery, 'base64')?.toString();
            await this.promisedSetState({offerCode: spkNumber, phoneNumber: "0", finishedFetchTrack: true});
            await this.trackSpk({source: utmSource?.toLowerCase()});
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <Layout className="layout">
                <Col style={(this.state.finishedFetchTrack) ? {display: 'none'} : {}} xl={{span: 6, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}}>
                    <Header className={`desktop-header-search`} style={{backgroundColor: '#fff', paddingBottom: 55, paddingTop: 7, borderBottom: "#f0f2f5 3px solid"}}>
                        <Row>
                            <Col span={3}><Link to={`/`}><LeftOutlined className={'hedaer2-back'}/></Link></Col>
                            <Col span={21}><b className={`track-header`}>Track Order</b></Col>
                        </Row>
                    </Header>

                    <div style={{minHeight: '92vh', backgroundColor: '#fff', padding: '4.5%', paddingTop: '2%'}}>
                        <div className={`track-title`}>Pencarian Status Pesanan Anda</div>
                        <div className={`track-description`}>Silakan lengkapi nomor telpon dan nomor SPK / nomor pengajuan pada form dibawah ini</div>
                        <div style={{textAlign: "center"}}>
                            <img src={'../assets/img/find.png'} alt={`amartachery-logo`}/>
                        </div>
                        <Form layout="vertical" className={`track-form`}>
                            <Form.Item>
                                <div className={`text-small-grey`}>Nomor Spk / Pengajuan</div>
                                <Input value={this.state.offerCode} placeholder="Masukan Nomor SPK / Nomor pengajuan" onChange={event => this.onFieldChange("offerCode", event.target.value)}/>
                            </Form.Item>
                            <Form.Item>
                                <div className={`text-small-grey`}>Nomor Telpon Pemilik</div>
                                <Input value={this.state.phoneNumber} maxLength={17} placeholder="Format: 08xxxxxxxx" onChange={event => this.onFieldChange("phoneNumber", event.target.value)}/>
                            </Form.Item>
                            <Button loading={this.state.fetchTrack} onClick={() => this.onClickSearch()} style={{background: "#ff4d4f", color: "#fff", borderColor: "#ff4d4f"}}> Lacak</Button>
                        </Form>
                    </div>
                </Col>

                <Col style={(this.state.finishedFetchTrack) ? {} : {display: 'none'}} xl={{span: 6, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}}>
                    <div style={(this.state.fetchTrack) ? {paddingTop: 200, paddingBottom: 200, textAlign: "center"} : {display: 'none'}}>
                        <Spin size="large"/>
                    </div>

                    <div style={(!this.state.fetchTrack) ? {} : {display: 'none'}}>
                        <Header className={`desktop-header-search`} style={{backgroundColor: '#fff', paddingBottom: 55, paddingTop: 7, borderBottom: "#f0f2f5 3px solid"}}>
                            <Row>
                                <Col span={3}><LeftOutlined onClick={this.onClickClose} className={'hedaer2-back'}/></Col>
                                <Col span={21}><b className={`track-header`}>SPK {this.state.offerCode}</b></Col>
                            </Row>
                        </Header>
                        <div style={{minHeight: '92vh', backgroundColor: '#fff', padding: '4.5%', paddingTop: '2%'}}>
                            <div style={(this.state.spkName) ? {} : {display: 'none'}}>
                                <div style={{fontSize: "120%", fontWeight: 480, paddingTop: 10, paddingBottom: 10}}>Kepemilikan</div>
                                <Typography.Text type="secondary" style={{paddingRight: 82}}> Nama: </Typography.Text> {this.state.spkName} <br/>
                                <Typography.Text type="secondary" style={{paddingRight: 30}}> Nomor Telpon: </Typography.Text> {this.state.spkPhoneNumber} <br/>
                                <Typography.Text type="secondary" style={{paddingRight: 75}}> Variant: </Typography.Text> {this.state.spkVariant?.toUpperCase()} <br/>
                                <Typography.Text type="secondary" style={{paddingRight: 80}}> Warna: </Typography.Text> {this.state.spkVariantColor?.toUpperCase()} <br/>
                                <Divider/>
                            </div>asdasd

                            <Timeline mode="left" style={{marginTop: 30}}>
                                {this.state.dataTrack.map((dt: any, x: number) =>
                                    <Timeline.Item key={x} color={`red`} label={dt.time}>
                                        <div className={`timeline-desc`}>{dt.title}</div>
                                    </Timeline.Item>
                                )}
                            </Timeline>
                        </div>
                    </div>

                </Col>
            </Layout>
        );
    }
}

export default withRouter(Track);