import React, {Component} from 'react';
import {VariantStates} from "./types/VariantTypes";
import {withRouter} from "../../hoc/withRouter";
import PageHeader from "../../component/header/PageHeader";
import FooterSection from "../../component/pageSection/FooterSection";
import {Button, Card, Col, Divider, message, Modal, Row, Spin} from "antd";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import VariantColorPreview from "../../component/pageSection/VariantColorPreview";
import currencyFormat from "../../helper/currencyFormat";
import {Link} from "react-router-dom";
import VariantDetail from "../../component/pageSection/VariantDetail";
import {appDefaultArea} from "../../config/appDataConfig/appConfig";
import Cookies from "universal-cookie";
import {FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton} from "react-share";
import {appCompanyName} from "../../config/appDataConfig/appConfig";


class Variant extends Component<any, VariantStates> {
    private cookies = new Cookies();
    private readonly initState!: VariantStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        const variantCode = match.params.param;

        if (!this.cookies.get('_amc-a')) {
            this.cookies.set('_amc-a', appDefaultArea);
        }

        this.initState = {
            isDesktop: false,
            showModalShare: false,
            desktopShowFixedBuyButton: false,
            fetching: true,
            variantCode: variantCode,
            listNewVariantColor: [],
            chosenNewVariantColor: {},
            chosenVariantColorIndex: 0,
            counterClickColor: 0,
            dataDetail: {},
            effectiveDate: "2023-01-01",
            submitButton: false,
            areaCode: (this.cookies.get('_amc-a')) ? this.cookies.get('_amc-a') : appDefaultArea,
        }
        this.state = {...this.initState}
    }

    onVariantColorChange = async (indexPosition: number, dataVariantColor: any) => {
        if (this.state.counterClickColor !== 0) {
            document.title = appCompanyName;
        }

        await this.promisedSetState({
            chosenVariantColorIndex: indexPosition,
            chosenNewVariantColor: dataVariantColor,
            counterClickColor: this.state.counterClickColor + 1
        });
    }

    async fetchVariant() {
        try {
            const dataVariantDetail = await trimitraCatalogServices.getCatalogVariant({
                areaCode: this.state.areaCode,
                variantCode: this.state.variantCode,
            });
            if (dataVariantDetail.data.data.length === 0) {
                this.props.history.push("/notfound");
                return false;
            }

            await this.promisedSetState({
                fetching: false,
                listNewVariantColor: dataVariantDetail.data.data
            });
        } catch (e) {
            this.props.history.push("/notfound");
        }

        try {
            const dataDetail = await trimitraCatalogServices.getCatalogDetailVariant({
                variantCode: this.state.variantCode,
            });

            if (dataDetail?.data?.data?.length > 0) {
                await this.promisedSetState({
                    fetching: false,
                    dataDetail: dataDetail?.data?.data[0],
                });
            }
        } catch (e) {
            console.log(e)
        }
    }

    async componentDidMount() {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, 10);

        window.addEventListener("resize", this.handleResize);
        window.addEventListener('scroll', this.handleScroll);
        this.handleResize();

        await this.fetchVariant();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('scroll', this.handleScroll);
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    handleScroll = () => {
        if (this.state.isDesktop) {
            if (window.scrollY >= 400) {
                this.setState({
                    desktopShowFixedBuyButton: true
                });
            } else {
                this.setState({
                    desktopShowFixedBuyButton: false
                });
            }
        }
    };

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    onAreaChange = async (data: any) => {
        this.cookies.set('_amc-a', data.city_group);
        await this.promisedSetState({
            areaCode: data.city_group,
            submitButton: true,
        });

        await this.fetchVariant();
        window.location.reload();
    }

    copyToClipboard = async (data: any) => {
        await navigator.clipboard.writeText(data);
        message.success('Link URL Berhasil Dicopy');
    }

    onSubmit = async () => {
        window.location.href = `/variant/${this.state.variantCode}/${this.state.chosenNewVariantColor.color_code}/purchase-plan`;
    }

    onCredit = async () => {
        window.location.href = `/variant/${this.state.variantCode}/${this.state.chosenNewVariantColor.color_code}/credit-form`;
    }

    render() {
        return (
            <React.Fragment>
                <PageHeader black={true} onAreaChangeProps={this.onAreaChange} areaCode={this.state.areaCode} isDesktop={this.state.isDesktop}/>

                <div style={this.state.fetching ? {textAlign: "center", padding: 70, minHeight: "70vh"} : {display: 'none'}}>
                    <Spin style={{marginTop: 100}} size={'large'} tip="Loading..."/>
                </div>
                <div style={this.state.fetching ? {display: 'none'} : {}}>
                    <Row className={`pt-100`}>
                        <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                            <Row>
                                <Col className={`p-20`} xxl={{span: 18}} xl={{span: 18}} lg={{span: 18}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <VariantColorPreview isDesktop={this.state.isDesktop} fetchData={this.state.fetching} fetchStock={[]} stockData={false} option={"NEW_VARIANT"} slidePost={this.state.chosenVariantColorIndex} newProductClass={this.state.listNewVariantColor} onClickClass={this.onVariantColorChange}/>
                                </Col>
                                <Col className={`p-20`} xxl={{span: 6}} xl={{span: 6}} lg={{span: 6}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <div style={this.state.isDesktop ? {paddingTop: 70} : {display: 'none'}}/>
                                    <br/>
                                    <div className={`pt-20`}/>
                                    <div className={`pt-10 card-promo-caption`}>{this.state.chosenNewVariantColor?.variant_code}</div>
                                    <div className={`pt-10 detail-product-title`}>{this.state.chosenNewVariantColor?.variant_name?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</div>
                                    <div className={`pt-10 card-promo-caption`}>OTR {this.state.areaCode}</div>
                                    <div className={`pt-10`}>
                                        <span style={this.state.chosenNewVariantColor?.price ? {} : {display: 'none'}} className={`pt-10 detail-product-price`}>{currencyFormat(this.state.chosenNewVariantColor?.price)} </span>
                                        <span style={!this.state.chosenNewVariantColor?.price ? {} : {display: 'none'}} className={`pt-10 detail-product-price`}>Not Available </span>
                                        <span style={this.state.chosenNewVariantColor?.price && this.state.effectiveDate ? {} : {display: 'none'}} className={`pt-10 card-effective-date-caption`}>*berlaku: {this.state.effectiveDate}</span></div>
                                    <div className={`pt-10`}>{this.state.chosenNewVariantColor?.color_name?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</div>
                                    <div className={`pt-20`}/>
                                    <div style={this.state.chosenNewVariantColor?.price ? {} : {display: 'none'}}>
                                        <Button onClick={() => this.onSubmit()} loading={this.state.submitButton} block={true} style={this.state.isDesktop ? {backgroundColor: '#384967', borderColor: '#384967', color: '#fff', margin: 2} : {display: 'none'}} danger size={"large"}>Pesan Sekarang</Button>
                                        <Button onClick={() => this.onCredit()} loading={this.state.submitButton} block={true} style={this.state.isDesktop ? {backgroundColor: '#384967', borderColor: '#384967', color: '#fff', margin: 2} : {display: 'none'}} danger size={"large"}>Ajukan Kredit</Button>
                                    </div>
                                    <div className={`pt-20`}/>

                                    <div style={this.state.isDesktop && this.state.desktopShowFixedBuyButton ? {} : {display: 'none'}} className={`fixed-top-button-scroll`}>
                                        <div className={`pt-10 detail-product-price`}>{this.state.chosenNewVariantColor?.variant_name?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</div>
                                        <div style={this.state.chosenNewVariantColor?.price ? {} : {display: 'none'}} className={`pt-10 card-promo-caption`}>{currencyFormat(this.state.chosenNewVariantColor?.price)}</div>
                                        <div style={!this.state.chosenNewVariantColor?.price ? {} : {display: 'none'}} className={`pt-10 card-promo-caption`}>Not Available</div>
                                        <div className={`pt-10`}/>
                                        <div style={this.state.chosenNewVariantColor?.price ? {} : {display: 'none'}}>
                                            <Button onClick={() => this.onSubmit()} loading={this.state.submitButton} style={{backgroundColor: '#384967', width: "160px", borderColor: '#384967', color: '#fff', margin: 2}} danger>Pesan Sekarang</Button>
                                            <Button onClick={() => this.onCredit()} loading={this.state.submitButton} style={{backgroundColor: '#384967', width: "160px", borderColor: '#384967', color: '#fff', margin: 2}} danger>Ajukan Kredit</Button>
                                        </div>
                                    </div>

                                    <div style={!this.state.isDesktop ? {} : {display: 'none'}} className={`fixed-bottom-button`}>
                                        <div style={this.state.chosenNewVariantColor?.price ? {} : {display: 'none'}}>
                                            <Row>
                                                <Col span={12}>
                                                    <Button onClick={() => this.onSubmit()} loading={this.state.submitButton} size={`large`} style={{width: "96%", backgroundColor: '#384967', borderColor: '#384967', color: '#fff'}} danger>Pesan Sekarang</Button>
                                                </Col>
                                                <Col span={12}>
                                                    <Button onClick={() => this.onCredit()} loading={this.state.submitButton} size={`large`} style={{width: "96%", backgroundColor: '#384967', borderColor: '#384967', color: '#fff'}} danger>Ajukan Kredit</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={`mt-20`} style={{backgroundColor: "#efefef"}}>
                        <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                            <div style={{textAlign: "center", fontSize: "170%"}}>Informasi Produk</div>
                            <Row className={`pt-10`}>
                                <Col className={`p-10`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                                    <a target={`_blank`} href={this.state.dataDetail?.detail_document?.[0]?.url}>
                                        <Card hoverable={true}><img alt={'icon'} style={{height: "20px"}} src={`/assets/img/icon/icons8-brochure-64.png`}/> &nbsp; Download Brosur</Card>
                                    </a>
                                </Col>
                                <Col className={`p-10`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                                    <Card onClick={() => this.setState({showModalShare: true})} hoverable={true}><img alt={'icon'} style={{height: "20px"}} src={`/assets/img/icon/icon8-share.png`}/> &nbsp; Share</Card>
                                </Col>
                                <Col className={`p-10`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                                    <Link to={`/promo`}>
                                        <Card hoverable={true}><img alt={'icon'} style={{height: "20px"}} src={`/assets/img/icon/icons8-sale-price-tag-80.png`}/> &nbsp; Dapatkan Promo</Card>
                                    </Link>
                                </Col>
                                <Col className={`p-10`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                                    <a target={`_blank`} href={`https://crm.amarta.vip/testdrive/${appCompanyName?.toLowerCase()}?m=${this.state.chosenNewVariantColor?.model_uuid}`}>
                                        <Card hoverable={true}><img alt={'icon'} style={{height: "20px"}} src={`/assets/img/icon/icons8-car-50.png`}/> &nbsp; Test Drive</Card>
                                    </a>
                                </Col>
                                <Col className={`p-10`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                                    <a target={`_blank`} href={`https://wa.me/message/HJMORPU4344FD1`}>
                                        <Card hoverable={true}><img alt={'icon'} style={{height: "20px"}} src={`/assets/img/icon/icons8-consultation-50.png`}/> &nbsp; Chat Pembelian</Card>
                                    </a>
                                </Col>
                                <Col className={`p-10`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                                    <Card hoverable={true}><img alt={'icon'} style={{height: "20px"}} src={`/assets/img/icon/icons8-installment-64.png`}/> &nbsp; Simulasi Cicilan <i style={{fontSize: "80%", color: "gray"}}>(Coming Soon)</i></Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <VariantDetail isDesktop={this.state.isDesktop} data={this.state.dataDetail}/>
                </div>

                <div style={{minHeight: 200}}/>
                <FooterSection/>

                <Modal
                    title={"Share " + this.state.chosenNewVariantColor?.variant_name?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}
                    open={this.state.showModalShare}
                    onCancel={() => this.setState({showModalShare: false})}
                    footer={[
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalShare: false})}> Cancel </Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <div className="Demo__container">
                            <div className="Demo__some-network">
                                <img onClick={event => this.copyToClipboard(window.location.href)} alt={'icon'} style={{height: "32px", cursor: "pointer"}} src={`/assets/img/icon/url-link.png`}/>
                                &nbsp; &nbsp;
                                <WhatsappShareButton
                                    url={window.location.href}
                                    title={"dapatkan penawaran terbaik "}
                                    separator=":: "
                                    className="Demo__some-network__share-button"
                                >
                                    <WhatsappIcon size={32}/>
                                </WhatsappShareButton>
                                &nbsp; &nbsp;
                                <TelegramShareButton
                                    url={window.location.href}
                                    title={"dapatkan penawaran terbaik "}
                                    className="Demo__some-network__share-button"
                                >
                                    <TelegramIcon size={32}/>
                                </TelegramShareButton>
                                &nbsp; &nbsp;
                                <FacebookShareButton
                                    url={window.location.href}
                                    quote={"dapatkan penawaran terbaik "}
                                    className="Demo__some-network__share-button"
                                >
                                    <FacebookIcon size={32}/>
                                </FacebookShareButton>
                                &nbsp; &nbsp;
                                <TwitterShareButton
                                    url={window.location.href}
                                    title={"dapatkan penawaran terbaik "}
                                    className="Demo__some-network__share-button"
                                >
                                    <TwitterIcon size={32}/>
                                </TwitterShareButton>
                                &nbsp; &nbsp;
                            </div>
                        </div>
                    </React.Fragment>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(Variant);
