import React, {Component} from "react";
import {ProductsStates} from "./types/ProductsTypes";
import {withRouter} from "../../hoc/withRouter";
import PageHeader from "../../component/header/PageHeader";
import {Button, Col, Drawer, Empty, Row, Select, Slider, Spin} from "antd";
import FooterSection from "../../component/pageSection/FooterSection";
import CurrencyInput from "react-currency-input-field";
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import ControlOutlined from '@ant-design/icons/ControlOutlined';
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import dynamicSort from "../../helper/dynamicSort";
import CardProduct from "../../component/card/CardProduct";
import {appDefaultArea} from "../../config/appDataConfig/appConfig";
import Cookies from "universal-cookie";
import {appCompanyName} from "../../config/appDataConfig/appConfig";

const marks = {300000000: '300jt', 500000000: '500jt', 700000000: '700jt'}

class MicroPage extends Component<any, ProductsStates> {
    private cookies = new Cookies();
    private readonly initState!: ProductsStates;

    constructor(props: any) {
        super(props);

        if (!this.cookies.get('_amc-a')) {
            this.cookies.set('_amc-a', appDefaultArea);
        }

        const {match} = this.props;
        let catalogCode: any = match.params.param ?? "Semua";
        document.title = `${appCompanyName} - Products`;

        this.initState = {
            fetching: false,
            isDesktop: false,
            openDrawer: false,
            newProductClass: "ALL",
            dataMicroPage: {},
            dataAnotherPage: [],
            listCatalog: [],
            dataCatalog: {},
            catalogSelected: catalogCode,
            listModel: [],
            listNewProduct: [],
            listNewProductFiltered: [],
            modelSelected: "Semua",
            filterMinPrice: 0,
            filterMaxPrice: 600000000,
            filterSorting: "relevan",
            completeFetchVariant: false,
            visibleFilter: false,
            searchText: "",
            areaCode: (this.cookies.get('_amc-a')) ? this.cookies.get('_amc-a') : appDefaultArea,
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    onFieldChange = <T extends keyof Pick<any, "filterMinPrice" | "filterMaxPrice" | "searchText" | "catalogSelected" | "modelSelected" | "filterSorting">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "searchText":
                currentState.searchText = value;
                break;
            case "filterMinPrice":
                currentState.filterMinPrice = value;
                break;
            case "filterMaxPrice":
                currentState.filterMaxPrice = value;
                break;
            case "catalogSelected":
                currentState.catalogSelected = value;
                break;
            case "modelSelected":
                currentState.modelSelected = value;
                break;
            case "filterSorting":
                currentState.filterSorting = value;
                break
        }

        this.setState({
            ...currentState,
        });
    }

    onChangeSlider = (value: any) => {
        this.setState({
            filterMinPrice: value[0],
            filterMaxPrice: value[1]
        });
    }

    isInsideCatalog = async (catCode: string, listCatVariant: any, listCatModel: any, model: string, variant: string) => {
        if (catCode === 'Semua') {
            return true;
        } else {
            if (listCatVariant.includes(variant)) {
                return true
            } else {
                return !!listCatModel.includes(model);
            }
        }
    }

    setupListNewProductFiltered = async () => {
        let filteredData: any[] = [];
        const filterMinPrice = (this.state.filterMinPrice === 0) ? 0 : this.state.filterMinPrice;
        const filterMaxPrice = (this.state.filterMaxPrice === 0) ? 99999999999 : this.state.filterMaxPrice;
        const filterSorting = this.state.filterSorting;

        // catalog check
        const f_catalog = this.state.dataCatalog[this.state.catalogSelected] ?? [];
        const listCatVariant: any = [];
        const listCatModel: any = [];
        for (const x of f_catalog) {
            if (listCatVariant.indexOf(x.variant) === -1) {
                listCatVariant.push(x.variant);
            }
            if (listCatModel.indexOf(x.model) === -1) {
                if (x.variant === null) {
                    listCatModel.push(x.model);
                }
            }
        }
        //end catalog check

        for (const el of this.state.listNewProduct) {
            if (this.state.newProductClass === 'ALL') {
                if (el.price >= filterMinPrice && el.price <= filterMaxPrice) {
                    if (this.state.modelSelected === 'Semua') {
                        const respCheck = await this.isInsideCatalog(this.state.catalogSelected, listCatVariant, listCatModel, el.model_name, el.variant_code)
                        if (respCheck) {
                            filteredData.push(el);
                        }
                    } else {
                        if (this.state.modelSelected === el.model_name) {
                            const respCheck = await this.isInsideCatalog(this.state.catalogSelected, listCatVariant, listCatModel, el.model_name, el.variant_code)
                            if (respCheck) {
                                filteredData.push(el);
                            }
                        }
                    }
                }
            }
        }

        if (filterSorting === 'minprice') {
            filteredData.sort(dynamicSort("price"));
        }
        if (filterSorting === 'maxprice') {
            filteredData.sort(dynamicSort("-price"));
        }

        await this.promisedSetState({
            listNewProductFiltered: filteredData,
            visibleFilter: false,
            searchText: "",
            openDrawer: false,
        });
    }

    async fetchProduct() {
        await this.promisedSetState({
            fetching: true,
        });
        try {
            // fetch all Catalogue
            const fetchCatalog = await trimitraCatalogServices.getCatalogMaster({
                cityGroup: this.state.areaCode,
                active: true,
                destinationLink: 'variant'
            });
            const listCatalog = [];
            const dataCatalog: any = {};
            for (const item of fetchCatalog.data.data) {
                listCatalog.push({
                    code: item.code,
                    name: item.name,
                });
                dataCatalog[item.code] = item.list_product;
            }
            await this.promisedSetState({
                listCatalog: listCatalog,
                dataCatalog: dataCatalog
            });

            // Fetch all Variant
            const dataVariantHome = await trimitraCatalogServices.getCatalogVariant({
                areaCode: this.state.areaCode
            });
            const listModel = [];
            for (const item of dataVariantHome.data.data) {
                if (listModel.indexOf(item.model_name) === -1) {
                    listModel.push(item.model_name);
                }
            }

            await this.promisedSetState({
                listNewProduct: dataVariantHome.data.data,
                listModel: listModel,
                completeFetchVariant: true,
                fetching: false,
            });

            await this.setupListNewProductFiltered();
        } catch (e) {
            console.log(e);
        }
    }

    async componentDidMount() {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, 10);

        window.addEventListener("resize", this.handleResize);
        this.handleResize();

        await this.fetchProduct();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    onAreaChange = async (data: any) => {
        this.cookies.set('_amc-a', data.city_group);
        await this.promisedSetState({
            areaCode: data.city_group,
        });
        await this.fetchProduct();
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <PageHeader black={true} onAreaChangeProps={this.onAreaChange} areaCode={this.state.areaCode} isDesktop={this.state.isDesktop}/>
                <Row className={`pt-100`} style={{backgroundColor: "#ffffff", minHeight: "60vh"}}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col xxl={{span: 5}} xl={{span: 5}} lg={{span: 5}} md={{span: 0}} sm={{span: 0}} xs={{span: 0}}>
                                <span style={{fontSize: "120%", fontWeight: 600}}>Filter</span>
                                <div className={`card-filter`}>
                                    <span className={`text-small-grey`}>Katalog Kendaraan:</span>
                                    <Select onChange={value => this.onFieldChange("catalogSelected", value)} value={this.state.catalogSelected} style={{width: '100%'}}>
                                        <Select.Option value="Semua">Semua</Select.Option>
                                        {this.state.listCatalog.map((item: any, i: number) =>
                                            <Select.Option key={i} value={item.code}>{item.name}</Select.Option>
                                        )}
                                    </Select>
                                    <div className={`pt-10`}/>
                                    <span className={`text-small-grey`}>Model Kendaraan:</span>
                                    <Select onChange={value => this.onFieldChange("modelSelected", value)} value={this.state.modelSelected} style={{width: '100%'}}>
                                        <Select.Option value="Semua">Semua</Select.Option>
                                        {this.state.listModel.map((item: any, i: number) =>
                                            <Select.Option key={i} value={item}>{item}</Select.Option>
                                        )}
                                    </Select>
                                    <div className={`pt-10`}/>
                                    <span className={`text-small-grey`}>Harga Minimum</span>
                                    <CurrencyInput
                                        className={'ant-input same-ant-input'}
                                        placeholder={'Harga Minimum'}
                                        allowDecimals={false}
                                        groupSeparator={'.'}
                                        decimalSeparator={','}
                                        prefix={'Rp. '}
                                        value={this.state.filterMinPrice}
                                        onValueChange={value => this.onFieldChange("filterMinPrice", value ? parseFloat(value) : 0)}
                                    />
                                    <div className={`pt-10`}/>
                                    <span className={`text-small-grey`}>Harga Maximum</span>
                                    <CurrencyInput
                                        className={'ant-input same-ant-input'}
                                        placeholder={'Harga Minimum'}
                                        allowDecimals={false}
                                        groupSeparator={'.'}
                                        decimalSeparator={','}
                                        prefix={'Rp. '}
                                        value={this.state.filterMaxPrice}
                                        onValueChange={value => this.onFieldChange("filterMaxPrice", value ? parseFloat(value) : 0)}
                                    />
                                    <div className={`pt-10`}/>
                                    <span className={`text-small-grey`}>Range Harga</span>
                                    <Slider
                                        min={100000000}
                                        max={1000000000}
                                        step={10000000}
                                        marks={marks}
                                        range defaultValue={[this.state.filterMinPrice, this.state.filterMaxPrice]}
                                        value={[this.state.filterMinPrice, this.state.filterMaxPrice]}
                                        onChange={this.onChangeSlider}
                                    />
                                    <div className={`pt-10`}/>
                                    <span className={`text-small-grey`}>Urutkan Berdasarkan:</span>
                                    <Select onChange={value => this.onFieldChange("filterSorting", value)} value={this.state.filterSorting} style={{width: '100%'}}>
                                        <Select.Option value="relevan">Relevan</Select.Option>
                                        <Select.Option value="minprice">Harga Terendah</Select.Option>
                                        <Select.Option value="maxprice">Harga Tertinggi</Select.Option>
                                    </Select>
                                    <div className={`pt-20`}/>
                                    <Button block={true} danger onClick={this.setupListNewProductFiltered} type="primary" icon={<SearchOutlined/>}>Terapkan Filter</Button>
                                    <div className={`pt-10`}/>
                                </div>
                            </Col>
                            <Col xxl={{span: 19}} xl={{span: 19}} lg={{span: 19}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                <div style={this.state.fetching ? {textAlign: "center", padding: 70, minHeight: "70vh"} : {display: 'none'}}>
                                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                                </div>
                                <div style={this.state.fetching ? {display: 'none'} : {}}>
                                    <div onClick={() => this.setState({openDrawer: true})} style={this.state.isDesktop ? {display: 'none'} : {textAlign: "right", cursor: "pointer", fontSize: "125%", paddingBottom: 20, paddingRight: 15, fontWeight: 450}}>Filter Pencarian <ControlOutlined/></div>
                                    <Row>
                                        {this.state.listNewProductFiltered.map((item: any, i: number) =>
                                            <Col xl={{span: 6}} lg={{span: 6}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}} key={i} style={(!item?.variant_code) ? {display: 'none'} : {padding: 10}}>
                                                <CardProduct data={item}/>
                                            </Col>
                                        )}
                                    </Row>
                                    <div style={this.state.listNewProductFiltered?.length < 1 ? {textAlign: "center", paddingTop: 100, paddingLeft: `${(this.state.isDesktop) ? "20%" : "10%"}`} : {display: 'none'}}>
                                        <Empty/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div style={{minHeight: 200}}/>
                <FooterSection/>

                <Drawer
                    title="Filter Pencarian"
                    placement="right"
                    open={this.state.openDrawer}
                    onClose={() => this.setState({openDrawer: false})}
                >
                    <React.Fragment>
                        <span className={`text-small-grey`}>Katalog Kendaraan:</span>
                        <Select onChange={value => this.onFieldChange("catalogSelected", value)} value={this.state.catalogSelected} style={{width: '100%'}}>
                            <Select.Option value="Semua">Semua</Select.Option>
                            {this.state.listCatalog.map((item: any, i: number) =>
                                <Select.Option key={i} value={item.code}>{item.name}</Select.Option>
                            )}
                        </Select>
                        <div className={`pt-10`}/>
                        <span className={`text-small-grey`}>Model Kendaraan:</span>
                        <Select onChange={value => this.onFieldChange("modelSelected", value)} value={this.state.modelSelected} style={{width: '100%'}}>
                            <Select.Option value="Semua">Semua</Select.Option>
                            {this.state.listModel.map((item: any, i: number) =>
                                <Select.Option key={i} value={item}>{item}</Select.Option>
                            )}
                        </Select>
                        <div className={`pt-10`}/>
                        <span className={`text-small-grey`}>Harga Minimum</span>
                        <CurrencyInput
                            className={'ant-input same-ant-input'}
                            placeholder={'Harga Minimum'}
                            allowDecimals={false}
                            groupSeparator={'.'}
                            decimalSeparator={','}
                            prefix={'Rp. '}
                            value={this.state.filterMinPrice}
                            onValueChange={value => this.onFieldChange("filterMinPrice", value ? parseFloat(value) : 0)}
                        />
                        <div className={`pt-10`}/>
                        <span className={`text-small-grey`}>Harga Maximum</span>
                        <CurrencyInput
                            className={'ant-input same-ant-input'}
                            placeholder={'Harga Minimum'}
                            allowDecimals={false}
                            groupSeparator={'.'}
                            decimalSeparator={','}
                            prefix={'Rp. '}
                            value={this.state.filterMaxPrice}
                            onValueChange={value => this.onFieldChange("filterMaxPrice", value ? parseFloat(value) : 0)}
                        />
                        <div className={`pt-10`}/>
                        <span className={`text-small-grey`}>Range Harga</span>
                        <Slider
                            min={100000000}
                            max={1000000000}
                            step={10000000}
                            marks={marks}
                            range defaultValue={[this.state.filterMinPrice, this.state.filterMaxPrice]}
                            value={[this.state.filterMinPrice, this.state.filterMaxPrice]}
                            onChange={this.onChangeSlider}
                        />
                        <div className={`pt-10`}/>
                        <span className={`text-small-grey`}>Urutkan Berdasarkan:</span>
                        <Select onChange={value => this.onFieldChange("filterSorting", value)} value={this.state.filterSorting} style={{width: '100%'}}>
                            <Select.Option value="relevan">Relevan</Select.Option>
                            <Select.Option value="minprice">Harga Terendah</Select.Option>
                            <Select.Option value="maxprice">Harga Tertinggi</Select.Option>
                        </Select>
                        <div className={`pt-20`}/>
                        <Button block={true} danger onClick={this.setupListNewProductFiltered} type="primary" icon={<SearchOutlined/>}>Terapkan Filter</Button>
                        <div className={`pt-10`}/>
                    </React.Fragment>
                </Drawer>
            </React.Fragment>
        );
    }
}

export default withRouter(MicroPage);
