import axios from "axios";
import {baseUrlOffer, xApiKeyOffer} from "../../config/apiConfig/apiConfig";
import {appCompanyCode} from "../../config/appDataConfig/appConfig";

const companyCode = appCompanyCode;

class OfferApiServices {
    private axios = axios.create({
        baseURL: baseUrlOffer,
    });

    public async getPublicOffer(params?: any) {
        try {
            return await this.axios.get<any>(`/public/offer/${companyCode}/${params.offerCode}`, {
                headers: {
                    "X-Api-Key": xApiKeyOffer,
                }
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getDetailPrebooking(params?: any) {
        try {
            return await this.axios.get<any>(`/prebooking/${companyCode}/${params.prebookingCode}`, {
                headers: {
                    "X-Api-Key": xApiKeyOffer,
                }
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getValidateOffer(params?: any) {
        const queries = {
            ...(params?.token && {
                token: params.token,
            }),
            ...(params?.checkBill && {
                check_bill: params.checkBill,
            }),
            ...(params?.promoCodes && {
                promo_codes: params.promoCodes,
            }),
            ...(params?.prebookingCode && {
                prebooking_code: params.prebookingCode,
            }),
            ...(params?.depositCode && {
                deposit_code: params.depositCode,
            }),
        }
        try {
            return await this.axios.get<any>(`/offer/validate-offer/${companyCode}/${params.offerCode}`, {
                headers: {
                    "X-Api-Key": xApiKeyOffer,
                },
                params: {...queries}
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async createOffer(inputData?: any) {
        try {
            return await this.axios.post<any>('/create-offer',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyOffer
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async createPreBooking(inputData?: any) {
        try {
            return await this.axios.post<any>('/create-prebooking',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyOffer
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async updateOffer(inputData?: any) {
        try {
            return await this.axios.put<any>('/update-offer',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyOffer
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

}

export const offerApiServices = new OfferApiServices();
