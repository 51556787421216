import React, {Component} from "react";
import './FooterSection.css'
import {Row} from "antd";
import Col from "antd/lib/col";
import {FacebookOutlined, YoutubeOutlined, InstagramOutlined, EnvironmentOutlined, PhoneOutlined, /*PhoneOutlined*/} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {appVersion} from "../../config/appDataConfig/appConfig";
import whatsappLink from "../../helper/whatsappLink";
import {appCompanyCode, appCompanyName, appName} from "../../config/appDataConfig/appConfig";

class FooterSection extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            loading: false,
        }
        this.state = {...this.initState}
    }

    render() {
        return (
            <React.Fragment>
                <footer className="footer-section">

                    <div className="container">
                        <div className="footer-content pt-5 pb-5">
                            <Row>
                                <Col className={`p-20`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                                    <div className="footer-widget">
                                        <div className="footer-logo">
                                            <Link to={`/`}>
                                                <img style={{height: 30, paddingLeft: 15, marginTop: 20, cursor: "pointer"}} className={`header-logo`} src={'/assets/img/icon-logo.png'} alt={`icon-logo`}/>
                                            </Link>
                                        </div>
                                        <div className="footer-text">
                                            <p><b>{appCompanyName}.</b> Kami adalah dealer mobil resmi Merk Otomotif {appName} di Indonesia. <br/> Dapatkan pengajuan kredit dengan bunga termurah dan proses cepat.</p>
                                        </div>
                                        <div className="footer-social-icon">
                                            <div className="footer-widget-heading">
                                                <h3>Follow Us</h3>
                                            </div>
                                            <a href={`https://www.instagram.com/${appCompanyCode}bandung/`} target={`_blank`}><InstagramOutlined/></a>
                                            <a href={`https://www.facebook.com/amarta${appCompanyCode}`} target={`_blank`}><FacebookOutlined/></a>
                                            <a href={`https://www.tiktok.com/@amarta${appCompanyCode}`} target={`_blank`}><img alt={`tiktok`} style={{height: "20px"}} src={`/assets/img/icon/tiktoklogo.png`}/></a>
                                            <a href={`https://www.youtube.com/@amarta${appCompanyCode}`} target={`_blank`}><YoutubeOutlined/></a>
                                        </div>
                                    </div>
                                </Col>
                                <Col className={`p-20`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                                    <div className="footer-widget">
                                        <div className="footer-widget-heading">
                                            <h3>Useful Links</h3>
                                        </div>
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a href={"https://trimitra.biz/"} target={"_blank"} rel={'noreferrer'}>About Us</a></li>
                                            <li><Link to={`/variant`}>List Variant</Link></li>
                                            <li><Link to={`/privasi`}>Kebijakan & Privasi</Link></li>
                                            <li><a href={"https://wa.me/message/HJMORPU4344FD1"} target={`_blank`}>Chat With Us</a></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col className={`p-20`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                                    <div className="footer-widget">
                                        <div>
                                            <EnvironmentOutlined style={{color: "#fff", fontSize: "250%"}}/>
                                            <div className="cta-text">
                                                <h4>Find us</h4>
                                                <span>Jl. Mochamad Toha No 136 Kota Bandung</span><br/>
                                                <span>Jl. Raya Caringin No 351 Kab Bandung Barat</span>
                                            </div>
                                        </div>
                                        <div className="single-cta">
                                            <PhoneOutlined style={{color: "#fff", fontSize: "250%"}}/>
                                            <div style={{cursor: "pointer"}} className="cta-text" onClick={() => whatsappLink('6281120057745', `Saya membutuhkan info mengenai amartachery %0a${window.location.href}`)}>
                                                <h4>Chat With Us</h4>
                                                <span>+62 811 2005 7745</span>
                                            </div>
                                        </div>
                                        {/*<div className="single-cta">
                                            <MailOutlined style={{color: "#fff", fontSize: "250%"}}/>
                                            <div className="cta-text">
                                                <h4>Mail us</h4>
                                                <span>info@invento.technology</span>
                                            </div>
                                        </div>*/}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="copyright-area p-20">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                                    <div className="copyright-text">
                                        <p>Copyright &copy; {new Date().getFullYear()} {appVersion}, All Right Reserved <span style={{color: "#E83939FF"}}>PT ARIMBI AMARTAPURA</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default FooterSection;
