import React from "react";
import Carousel from "antd/lib/carousel";
import "./Testimonial.css";
import TestimonialDetail from "./TestimonialDetail";
import {appName} from "../../config/appDataConfig/appConfig";

const dataPage0 = [
    {
        name: "Qiyee Chasya",
        testimonial: "keiritan konsumsi BBM nya, itu yg paling penting saat ini. congrats and welcome back " + appName + ", I can't wait to see your improvement. Good luck " + appName + ".",
    },
    {
        name: "Apuk Yorke",
        testimonial: "nilai plus: Garansi dan warranty wow, Hp dan torsi wow, Harga wow, Interior wow, Fitur dan teknologi wow, Safety wow, tunggu dealer nya ada deh..saya pasti beli..mesin 2.0 turbo super ganas"
    },
    {
        name: "Cayman",
        testimonial: "ga perlu alergi dengan Brand Tiongkok dengan catatan mereka bisa memberikan aftersales yang baik dan mutu serta kualitas yang sebanding dengan harganya. konsumen akan di untungkan dengan adanya persaingan brand2 besar. selama ini harga jual brand2 jepang menurut saya sedikit overpriced.",
    },
    {
        name: "Qiyee Chasya",
        testimonial: "keiritan konsumsi BBM nya, itu yg paling penting saat ini. congrats and welcome back " + appName + ", I can't wait to see your improvement. Good luck " + appName + ".",
    },
    {
        name: "Apuk Yorke",
        testimonial: "nilai plus: Garansi dan warranty wow, Hp dan torsi wow, Harga wow, Interior wow, Fitur dan teknologi wow, Safety wow, tunggu dealer nya ada deh..saya pasti beli..mesin 2.0 turbo super ganas"
    },
    {
        name: "Cayman",
        testimonial: "ga perlu alergi dengan Brand Tiongkok dengan catatan mereka bisa memberikan aftersales yang baik dan mutu serta kualitas yang sebanding dengan harganya. konsumen akan di untungkan dengan adanya persaingan brand2 besar. selama ini harga jual brand2 jepang menurut saya sedikit overpriced.",
    },
    {
        name: "Hi, 1626",
        testimonial: "Ada adaptive cruise control ges, mantep nih. kalo varian warna silvernya semacam warna silver di produk MG, bakal kelihatan lebih mewah lagi.",
    },
];

const Testimonial = () => {
    const PrevArrow = (props: any) => {
        const {onClick} = props;
        return (
            <div className="slick-arrow slick-prev" onClick={onClick}>
                <i className="fas fa-chevron-left"></i>
            </div>
        );
    }

    const NextArrow = (props: any) => {
        const {onClick} = props;
        return (
            <div className="slick-arrow slick-next" onClick={onClick}>
                <i className="fas fa-chevron-right"></i>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className={`footer-r-title`}>
                Testimonial
            </div>

            <div style={{marginLeft: 35, marginRight: 35}}>
                <Carousel
                    draggable={true}
                    arrows
                    prevArrow={<PrevArrow/>}
                    nextArrow={<NextArrow/>}
                >
                    {dataPage0.map((item: any, x: number) =>
                        <div key={x}>
                            <div className={`chosen-product`} style={{marginBottom: 10}}>
                                <TestimonialDetail name={item.name} testimony={item.testimonial}/>
                            </div>
                        </div>
                    )}

                    {/*<div>
                        <div className={`chosen-product`} style={{marginBottom: 10}}>
                            <TestimonialDetail name={dataPage1[0].name} testimony={dataPage1[0].testimonial}/>
                            <TestimonialDetail name={dataPage1[1].name} testimony={dataPage1[1].testimonial}/>
                            <TestimonialDetail name={dataPage1[2].name} testimony={dataPage1[2].testimonial}/>
                        </div>
                    </div>
                    <div>
                        <div className={`chosen-product`} style={{marginBottom: 10}}>
                            <TestimonialDetail name={dataPage2[0].name} testimony={dataPage2[0].testimonial}/>
                            <TestimonialDetail name={dataPage2[1].name} testimony={dataPage2[1].testimonial}/>
                            <TestimonialDetail name={dataPage2[2].name} testimony={dataPage2[2].testimonial}/>
                        </div>
                    </div>
                    <div>
                        <div className={`chosen-product`} style={{marginBottom: 10}}>
                            <TestimonialDetail name={dataPage3[0].name} testimony={dataPage3[0].testimonial}/>
                            <TestimonialDetail name={dataPage3[1].name} testimony={dataPage3[1].testimonial}/>
                            <TestimonialDetail name={dataPage3[2].name} testimony={dataPage3[2].testimonial}/>
                        </div>
                    </div>
                    <div>
                        <div className={`chosen-product`} style={{marginBottom: 10}}>
                            <TestimonialDetail name={dataPage4[0].name} testimony={dataPage4[0].testimonial}/>
                            <TestimonialDetail name={dataPage4[1].name} testimony={dataPage4[1].testimonial}/>
                            <TestimonialDetail name={dataPage4[2].name} testimony={dataPage4[2].testimonial}/>
                        </div>
                    </div>
                    <div>
                        <div className={`chosen-product`} style={{marginBottom: 10}}>
                            <TestimonialDetail name={dataPage5[0].name} testimony={dataPage5[0].testimonial}/>
                            <TestimonialDetail name={dataPage5[1].name} testimony={dataPage5[1].testimonial}/>
                            <TestimonialDetail name={dataPage5[2].name} testimony={dataPage5[2].testimonial}/>
                        </div>
                    </div>*/}

                </Carousel>
            </div>
        </React.Fragment>
    );
}

export default Testimonial;
