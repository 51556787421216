import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Provider} from "react-redux";
import {store} from './redux';
import Login from "./screen/auth/Login";
import Register from "./screen/auth/Register";
import Home from "./screen/home/Home";
import Promo from "./screen/promo/Promo";
import MicroPage from "./screen/microPage/MicroPage";
import Test from "./screen/test/Test";
import MyProfile from "./screen/myProfile/MyProfile";
import MyBiodata from "./screen/myProfile/MyBiodata";
import MySubmission from "./screen/myProfile/MySubmission";
import ResetPassword from "./screen/auth/ResetPassword";
import Products from "./screen/products/Products";
import Variant from "./screen/products/Variant";
import PurchasePlan from "./screen/products/PurchasePlan";
import CreditForms from "./screen/products/CreditForms";
import PreBooking from "./screen/preBooking/PreBooking";
import Spk from "./screen/spk/Spk";
import SpkCredit from "./screen/spk/SpkCredit";
import Closing from "./screen/spk/Closing";
import DetailPrebooking from "./screen/preBooking/DetailPrebooking";
import Track from "./screen/track/Track";

import NotFound from "./screen/home/NotFound";
import PrivacyPage from "./screen/microPage/PrivacyPage";

function App() {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/test" caseSensitive={false} element={<Test/>}/>
                    <Route path="/login" caseSensitive={false} element={<Login/>}/>
                    <Route path="/register" caseSensitive={false} element={<Register/>}/>
                    <Route path="/reset-password" caseSensitive={false} element={<ResetPassword/>}/>
                    <Route path={"/my-profile"} caseSensitive={false} element={<MyProfile/>}/>
                    <Route path={"/my-biodata"} caseSensitive={false} element={<MyBiodata/>}/>
                    <Route path={"/my-submission"} caseSensitive={false} element={<MySubmission/>}/>
                    <Route path={"/privasi"} caseSensitive={false} element={<PrivacyPage/>}/>
                    <Route path={"/products/:param/:param2"} caseSensitive={false} element={<Products/>}/>
                    <Route path={"/products/:param"} caseSensitive={false} element={<Products/>}/>
                    <Route path={"/products"} caseSensitive={false} element={<Products/>}/>
                    <Route path={"/variant/:param"} caseSensitive={false} element={<Variant/>}/>
                    <Route path={"/variant/:param/:param2/purchase-plan"} caseSensitive={false} element={<PurchasePlan/>}/>
                    <Route path={"/variant/:param/:param2/credit-form"} caseSensitive={false} element={<CreditForms/>}/>
                    <Route path={"/p/:micropagecode"} caseSensitive={false} element={<MicroPage/>}/>
                    <Route path="/promo" caseSensitive={false} element={<Promo/>}/>
                    <Route path={"/booking"} caseSensitive={false} element={<PreBooking/>}/>
                    <Route path={"/spk/:offercode"} caseSensitive={false} element={<Spk/>}/>
                    <Route path={"/spk-credit/:offercode"} caseSensitive={false} element={<SpkCredit/>}/>
                    <Route path={"/closing"} caseSensitive={false} element={<Closing/>}/>
                    <Route path={"/booking/:prebookingcode"} caseSensitive={false} element={<DetailPrebooking/>}/>
                    <Route path={"/track"} caseSensitive={false} element={<Track/>}/>
                    <Route path="/" caseSensitive={false} element={<Home/>}/>

                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </Router>
        </Provider>
    );
}

export default App;
