import axios from "axios";
import {baseUrlTrack, xApiKeyTrack} from "../../config/apiConfig/apiConfig";

const companyCode = 'chery';

class TrackApiServices {
    private axios = axios.create({
        baseURL: baseUrlTrack,
    });

    public async getTrack(params?: any) {
        const queries = {
            ...(params?.type && {
                type: params.type,
            }),
            ...(params?.phoneNumber && {
                phone_number: params.phoneNumber,
            }),
        }
        try {
            return await this.axios.get<any>(`/track/${companyCode}/${params?.offerCode}`, {
                headers: {
                    "X-Api-Key": xApiKeyTrack,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }
}

export const trackApiServices = new TrackApiServices();
