import axios from "axios";
import {baseUrlB2b} from "../../config/apiConfig/apiConfig";
import {appCompanyName} from "../../config/appDataConfig/appConfig";

class B2bServices {
    private axios = axios.create({
        baseURL: baseUrlB2b,
    });

    public async getListLeasing() {
        const queries = {}
        try {
            return await this.axios.get<any>(`/public/available-leasing/e-survey?products=4W_NEW`, {
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async createSurvey(inputData?: any) {
        try {
            return await this.axios.post<any>(`/survey-order/${appCompanyName?.toLowerCase()}/add`,
                inputData, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
        } catch (e: any) {
            throw e;
        }
    }
}

export const b2bServices = new B2bServices();
