import React, {Component} from 'react';
import {ClosingStates} from "./types/ClosingTypes";
import {withRouter} from "../../hoc/withRouter";
import PageHeader from "../../component/header/PageHeader";
import FooterSection from "../../component/pageSection/FooterSection";
import {Breadcrumb, Col, Input, Row, Form, Button, Modal, Result, Image, Divider} from "antd";
import {appDefaultArea} from "../../config/appDataConfig/appConfig";
import Cookies from "universal-cookie";
import {testService} from "../../services/TestService";
import generateMd5 from "../../helper/md5";
import {offerApiServices} from "../../services/offer/OfferApiService";
import CurrencyInput from "react-currency-input-field";
import Typography from "antd/lib/typography";
import currencyFormat from "../../helper/currencyFormat";
import {appName, appCompanyCode, appCompanyName} from "../../config/appDataConfig/appConfig";

class Closing extends Component<any, ClosingStates> {
    private cookies = new Cookies();
    private readonly initState!: ClosingStates;

    constructor(props: any) {
        super(props);
        this.initState = {
            isDesktop: false,
            offerCode: "",
            disableOfferCode: false,
            areaCode: (this.cookies.get('_amc-a')) ? this.cookies.get('_amc-a') : appDefaultArea,

            processing: false,
            successFetch: false,
            successShow: false,
            finalShow: false,

            dataOffer: {},
            promoCode: "",
            prebookingCode: "",
            depositCode: "",
            purchaseMethod: "",
            ownerName: "",
            variantName: "",
            otrPrice: 0,

            dataTest: [],
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    async componentDidMount() {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, 10);

        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();

        // await this.fetchTest();
    }

    fetchTest = async () => {
        try {
            const x = await testService.getFeeds();
            this.setState({
                dataTest: x.data.data.data
            });
        } catch (e) {
            console.log(e);
        }
    }

    onAreaChange = async (data: any) => {
        this.cookies.set('_amc-a', data.city_group);
        await this.promisedSetState({
            areaCode: data.city_group,
        });
    }

    onFieldChange = async <T extends keyof Pick<any, "offerCode" | "promoCode" | "prebookingCode" | "depositCode">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "offerCode":
                currentState.offerCode = value?.toUpperCase()?.trim();
                break;
            case "promoCode":
                currentState.promoCode = value?.toUpperCase()?.trim();
                break;
            case "prebookingCode":
                currentState.prebookingCode = value?.toUpperCase()?.trim();
                break;
            case "depositCode":
                currentState.depositCode = value?.toUpperCase()?.trim();
                break;
        }

        await this.promisedSetState({
            ...currentState,
        });
    }
    onCheck = async (type: string) => {
        if (!this.state.offerCode) {
            return Modal.error({title: 'Proses Gagal', content: 'Mohon lengkapi data'});
        }

        const hash = generateMd5(this.state.offerCode);
        await this.promisedSetState({
            processing: true,
            disableOfferCode: true,
            successFetch: false,
        });

        let getParams = {};
        if (type === "ADVANCE") {
            getParams = {
                companyCode: appCompanyCode,
                offerCode: this.state.offerCode,
                token: hash,
                checkBill: "true",
                promoCodes: this.state.promoCode,
                prebookingCode: this.state.prebookingCode,
                depositCode: this.state.depositCode
            };
        } else {
            getParams = {
                companyCode: appCompanyCode,
                offerCode: this.state.offerCode,
                token: hash,
            }
        }

        try {
            const result = await offerApiServices.getValidateOffer(getParams);
            const dataOffer = result.data.meta;
            this.setState({
                processing: false,
                ownerName: dataOffer.id_card_owner.full_name.toUpperCase(),
                purchaseMethod: dataOffer.purchase_method.toUpperCase(),
                variantName: dataOffer.vehicle.variant_name.toUpperCase(),
                otrPrice: dataOffer.price_and_bill.otr,
                successFetch: true,
                dataOffer: dataOffer,
            });

            if (type === "ADVANCE") {
                this.setState({
                    successFetch: false,
                    finalShow: true,
                });
            } else {
                this.setState({
                    successFetch: true,
                    finalShow: false,
                    promoCode: (dataOffer.detail_promo.length > 0) ? dataOffer.detail_promo[0].code : "",
                });
            }
        } catch (e: any) {
            this.setState({
                processing: false,
                successFetch: false,
            });
            return Modal.error({title: 'Proses Gagal', content: e.toString()});
        }
    }

    onBack = async () => {
        this.setState({
            disableOfferCode: false,
            processing: false,
            successFetch: false,
            successShow: false,
            finalShow: false,
        });
    }

    onSubmit = async () => {
        if (!this.state.prebookingCode && !this.state.depositCode) {
            return Modal.error({title: 'Proses Gagal', content: 'Mohon lengkapi data'});
        }

        await this.promisedSetState({
            processing: true,
        });

        try {
            await offerApiServices.updateOffer({
                update_type: "prebooking",
                company: appCompanyCode,
                offer_code: this.state.offerCode,
                admin_id: "0",
                admin_name: appCompanyName?.toLowerCase(),
                promo_codes: (this.state.promoCode) ? [this.state.promoCode] : [],
                prebooking_code: this.state.prebookingCode,
                deposit_code: this.state.depositCode
            }).then(successData => {
                this.setState({
                    successShow: true,
                    processing: false,
                });
            });
        } catch (e: any) {
            this.setState({
                processing: false,
            });
            return Modal.error({title: 'Proses Gagal', content: e.toString()});
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <PageHeader black={true} onAreaChangeProps={this.onAreaChange} areaCode={this.state.areaCode} isDesktop={this.state.isDesktop}/>

                <Row className={`pt-100`}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Breadcrumb className={`pl-20`}>
                            <Breadcrumb.Item>Amarta ${appName}</Breadcrumb.Item>
                            <Breadcrumb.Item>Closing</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>

                <Row className={`mt-20`} style={!this.state.successShow ? {} : {display: 'none'}}>
                    <Col xxl={{span: 8, offset: 8}} xl={{span: 8, offset: 8}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Form.Item>
                            <i className={`text-small-grey`}>Nomor SPK <b style={{color: "#e52a2a"}}>*</b> </i>
                            <Input disabled={this.state.disableOfferCode} value={this.state.offerCode} placeholder="Nomor SPK" onChange={event => this.onFieldChange("offerCode", event.target.value)}/>
                        </Form.Item>

                        <div style={this.state.successFetch ? {} : {display: 'none'}}>
                            <Form.Item>
                                <i className={`text-small-grey`}>Nama Pemilik </i>
                                <Input disabled={true} value={this.state.ownerName} placeholder="Nama Pemilik"/>
                            </Form.Item>
                            <Form.Item>
                                <i className={`text-small-grey`}>Metode Pembelian </i>
                                <Input disabled={true} value={this.state.purchaseMethod} placeholder="Metode Pembelian"/>
                            </Form.Item>
                            <Form.Item>
                                <i className={`text-small-grey`}>Nama Variant </i>
                                <Input disabled={true} value={this.state.variantName} placeholder="Nama Variant"/>
                            </Form.Item>
                            <Form.Item>
                                <i className={`text-small-grey`}>OTR Variant </i>
                                <CurrencyInput
                                    disabled={true}
                                    className={'ant-input same-ant-input'}
                                    placeholder={'OTR'}
                                    allowDecimals={false}
                                    groupSeparator={'.'}
                                    decimalSeparator={','}
                                    value={this.state.otrPrice}
                                />
                            </Form.Item>
                        </div>

                        <div style={!this.state.successFetch && !this.state.finalShow ? {} : {display: 'none'}}>
                            <Form.Item>
                                <Button onClick={() => this.onCheck("DEFAULT")} loading={this.state.processing} style={{backgroundColor: '#384967', width: "160px", borderColor: '#384967', color: '#fff'}} danger>Cek SPK</Button>
                            </Form.Item>
                        </div>
                    </Col>

                    <Col style={this.state.successFetch ? {} : {display: 'none'}} xxl={{span: 8, offset: 8}} xl={{span: 8, offset: 8}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Promo</i>
                            <Input value={this.state.promoCode} placeholder="Kode Promo" onChange={event => this.onFieldChange("promoCode", event.target.value)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kode Booking</i>
                            <Input value={this.state.prebookingCode} placeholder="Kode Booking" onChange={event => this.onFieldChange("prebookingCode", event.target.value)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kode Deposit</i> <i style={{color: "#384967", fontSize: "80%"}}>* (gunakan comma separator untuk multiple kode deposit)</i>
                            <Input value={this.state.depositCode} placeholder="Kode Deposit" onChange={event => this.onFieldChange("depositCode", event.target.value)}/>
                        </Form.Item>

                        <Form.Item>
                            <Button onClick={() => this.onCheck("ADVANCE")} loading={this.state.processing} style={{backgroundColor: '#384967', width: "160px", borderColor: '#384967', color: '#fff'}} danger>Verifikasi SPK</Button>

                        </Form.Item>
                    </Col>
                </Row>

                <Row className={`mt-20`} style={this.state.finalShow ? {} : {display: 'none'}}>
                    <Col xxl={{span: 8, offset: 8}} xl={{span: 8, offset: 8}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Typography.Text type="secondary" style={{paddingRight: 47}}> Nomor SPK: </Typography.Text> {this.state.offerCode} <br/>
                        <Typography.Text type="secondary" style={{paddingRight: 4}}> Metode Pembelian: </Typography.Text> {(this.state?.dataOffer?.purchase_method === "cash") ? "TUNAI" : "KREDIT"} <br/>
                        <Typography.Text type="secondary" style={{paddingRight: 33}}> Nama Pemilik: </Typography.Text> {this.state.dataOffer?.id_card_owner?.full_name} <br/>
                        <Typography.Text type="secondary" style={{paddingRight: 75}}> Variant: </Typography.Text> {this.state.dataOffer?.vehicle?.variant_name?.toUpperCase()} <br/>
                        <Divider/>
                        <Typography.Text type="secondary" style={{paddingRight: 70}}> OTR: </Typography.Text> {currencyFormat(this.state.dataOffer?.price_and_bill?.otr)} <br/>
                        <span style={this.state?.dataOffer?.purchase_method === 'credit' ? {} : {display: 'none'}}><Typography.Text type="secondary" style={{paddingRight: 78}}> DP: </Typography.Text> {currencyFormat(this.state.dataOffer?.credit?.dp_amount)} <br/></span>
                        <Typography.Text type="secondary" style={{paddingRight: 25}}> Biaya Order: </Typography.Text> {currencyFormat(this.state.dataOffer?.price_and_bill?.order_fee)} <br/>
                        <Typography.Text type="secondary" style={{paddingRight: 55}}> Promo: </Typography.Text> {currencyFormat(this.state.dataOffer?.promo_data?.amount)} <br/>
                        <Typography.Text type="secondary" style={{paddingRight: 46}}> Booking: </Typography.Text> {currencyFormat(this.state.dataOffer?.data_prebooking?.prebookingAmount)} <br/>
                        <Typography.Text type="secondary" style={{paddingRight: 49}}> Deposit: </Typography.Text> {currencyFormat(this.state.dataOffer?.data_prebooking?.depositAmount)} <br/>
                        <Divider/>
                        <Typography.Text type="secondary" style={{paddingRight: 12}}> <b>Total Tagihan:</b> </Typography.Text> {currencyFormat(this.state.dataOffer?.total_bill)} <br/>
                        <Divider/>

                        <Button onClick={() => this.onSubmit()} loading={this.state.processing} style={{backgroundColor: '#384967', width: "160px", borderColor: '#384967', color: '#fff'}} danger>Closing SPK</Button> &nbsp; <Button onClick={() => this.onBack()} loading={this.state.processing}>Back</Button>
                    </Col>
                </Row>

                <Row className={`mt-20`} style={this.state.successShow ? {} : {display: 'none'}}>
                    <Col xxl={{span: 8, offset: 8}} xl={{span: 8, offset: 8}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Result status="success" title="Pengisian Form Sukses" subTitle={`Terima kasih telah melengkapi data SPK ${this.state.offerCode}`}/>
                    </Col>
                </Row>

                <div>
                    <Row>
                        {this.state.dataTest.map((item: any, i: number) =>
                            <Col span={6} key={i}>
                                <Image src={item.art_url}/>
                            </Col>
                        )}
                    </Row>
                </div>

                <div style={{minHeight: 320}}/>
                <FooterSection/>
            </React.Fragment>
        );
    }
}

export default withRouter(Closing);
