import React, {Component} from 'react';
import {DetailPrebookingStates} from "./types/DetailPrebookingTypes";
import {withRouter} from "../../hoc/withRouter";
import PageHeader from "../../component/header/PageHeader";
import FooterSection from "../../component/pageSection/FooterSection";
import {Breadcrumb, Card, Col, message, Image, Row, Tooltip, Alert, Spin, Modal} from "antd";
import {appDefaultArea} from "../../config/appDataConfig/appConfig";
import Cookies from "universal-cookie";
import CopyOutlined from "@ant-design/icons/CopyOutlined";
import currencyFormat from "../../helper/currencyFormat";
import {offerApiServices} from "../../services/offer/OfferApiService";
import {appName, appCompanyName} from "../../config/appDataConfig/appConfig";

class Promo extends Component<any, DetailPrebookingStates> {
    private cookies = new Cookies();
    private readonly initState!: DetailPrebookingStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        const prebookingCode = match.params.prebookingcode ?? "";

        if (!this.cookies.get('_amc-a')) {
            this.cookies.set('_amc-a', appDefaultArea);
        }

        this.initState = {
            isDesktop: false,
            prebookingCode: prebookingCode,
            areaCode: (this.cookies.get('_amc-a')) ? this.cookies.get('_amc-a') : appDefaultArea,
            fetchData: true,
            prebookingAmount: 0,
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    async componentDidMount() {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, 10);

        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();

        try {
            const dataPrebooking = await offerApiServices.getDetailPrebooking({prebookingCode: this.state.prebookingCode});
            this.setState({
                prebookingAmount: dataPrebooking.data.data.payment.amount,
                fetchData: false,
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Kode Prebooking tidak valid'
            });
        }
    }

    onAreaChange = async (data: any) => {
        this.cookies.set('_amc-a', data.city_group);
        await this.promisedSetState({
            areaCode: data.city_group,
        });
    }

    copyToClipboard = async (data: any, text: string) => {
        await navigator.clipboard.writeText(data);
        message.success(`${text} berhasil dicopy`);
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <PageHeader black={true} onAreaChangeProps={this.onAreaChange} areaCode={this.state.areaCode} isDesktop={this.state.isDesktop}/>

                <Row className={`pt-100`}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Breadcrumb className={`pl-20`}>
                            <Breadcrumb.Item>Amarta {appName}</Breadcrumb.Item>
                            <Breadcrumb.Item>Prebooking</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>

                <div style={(this.state.fetchData) ? {textAlign: "center", padding: 20, minHeight: 300} : {display: 'none'}}>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                </div>

                <div style={(!this.state.fetchData) ? {} : {display: 'none'}}>
                    <Row className={`mt-20`} style={{backgroundColor: "#fff"}}>
                        <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                            <div className={`content-top-padding-20 height-100`}>
                                <div style={{textAlign: "center", fontSize: "200%"}}>Prebooking {appCompanyName}</div>
                                <div style={{color: "gray", textAlign: "center", padding: 20}}>Terimakasih telah mengisi form Prebooking dengan lengkap, silakan lakukan pembayaran sesuai dengan detail dibawah ini.</div>

                                <div style={{textAlign: "center", fontSize: "110%", paddingTop: 30}}>
                                    <span style={{color: "gray", paddingBottom: 10}}>Kode Prebooking:</span> <br/>
                                    <div style={{marginTop: 10}}><b style={{fontSize: "120%"}}>{this.state.prebookingCode}</b>
                                        <Tooltip title="Click untuk copy Kode Prebooking" color={'cyan'}>
                                            <CopyOutlined style={{color: "#259261", paddingLeft: 7}} className={'small-avatar-link'} onClick={event => this.copyToClipboard(this.state.prebookingCode, "Kode Prebooking")}/>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{marginTop: 30}}>
                        <Col xxl={{span: 6, offset: 9}} xl={{span: 6, offset: 9}} lg={{span: 6, offset: 6}} md={{span: 20, offset: 2}} sm={{span: 20, offset: 2}} xs={{span: 20, offset: 2}}>
                            <Card bodyStyle={{width: "100%"}}>
                                <Row>
                                    <Col span={18}>
                                        <span style={{color: "grey"}}>Virtual Account BCA:</span><br/>
                                        <span style={{fontSize: '120%'}}>
                                        {this.state.prebookingCode}
                                            <Tooltip title="Click untuk copy Kode Virtual Account" color={'cyan'}>
                                            <CopyOutlined style={{color: "#259261", paddingLeft: 7}} className={'small-avatar-link'} onClick={event => this.copyToClipboard(this.state.prebookingCode, "Nomor Virtual Account")}/>
                                        </Tooltip>
                                    </span><br/><br/>
                                        <span style={{color: "grey"}}>Jumlah Tagihan:</span><br/>
                                        <span style={{fontSize: '120%'}}>{currencyFormat(this.state.prebookingAmount)}</span>
                                    </Col>
                                    <Col span={6}>
                                        <Image preview={false} style={{width: 70, marginTop: 5}} src={`https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/asset/logo/payment/bca.jpg`}/>
                                    </Col>
                                </Row>
                            </Card><br/> <br/>
                            <Alert
                                message="Informasi"
                                description={`Untuk memudahkan pelacakan dan informasi terkait transaksi anda, kami menghimbau anda untuk menyimpan Kode Prebooking. click untuk copy`}
                                type="info"
                                style={{cursor: "pointer"}}
                                showIcon
                                onClick={event => this.copyToClipboard(this.state.prebookingCode, "Kode Prebooking")}
                            /><br/>
                        </Col>
                    </Row>
                </div>
                <div style={{minHeight: 200}}/>
                <FooterSection/>
            </React.Fragment>
        );
    }
}

export default withRouter(Promo);
