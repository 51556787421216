import React, {Component} from 'react';
import {HomeStates} from "./types/HomeTypes";
import {withRouter} from "../../hoc/withRouter";
import PageHeader from "../../component/header/PageHeader";
import HomeBanner from "../../component/pageSection/HomeBanner";
import FooterSection from "../../component/pageSection/FooterSection";
import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined';
import {Col, Divider, Empty, Row, Spin} from "antd";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import CardProduct from "../../component/card/CardProduct";
import {Link} from "react-router-dom";
import FooterReasonBuy from "../../component/pageSection/FooterReasonBuy";
import Testimonial from "../../component/pageSection/Testimonial";
import {appDefaultArea} from "../../config/appDataConfig/appConfig";
import Cookies from "universal-cookie";
import {appCompanyCode} from "../../config/appDataConfig/appConfig";

class Home extends Component<any, HomeStates> {
    private cookies = new Cookies();
    private readonly initState!: HomeStates;

    constructor(props: any) {
        super(props);

        if (!this.cookies.get('_amc-a')) {
            this.cookies.set('_amc-a', appDefaultArea);
        }

        this.initState = {
            isDesktop: false,
            fetching: true,
            listHomepageCatalog: [],
            listHomepageCuration: [],
            areaCode: (this.cookies.get('_amc-a')) ? this.cookies.get('_amc-a') : appDefaultArea,
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    async componentDidMount() {
        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();


        await this.fetchCityCuration();
    }

    fetchCityCuration = async () => {
        await this.promisedSetState({
            fetching: true,
            listHomepageCatalog: [],
            listHomepageCuration: [],
        });

        try {
            const dataCurationHome = await trimitraCatalogServices.getCurationHomepage({areaCode: this.state.areaCode});
            const listHomePageCuration = dataCurationHome?.data?.data?.catalog_list ?? [];
            const tempFullCatalogResponse: any = [];
            await Promise.all(listHomePageCuration.map(async (item: any) => {
                try {
                    const dataCuration = await trimitraCatalogServices.getListCatalog({
                        areaCode: this.state.areaCode,
                        code: item.code
                    });
                    tempFullCatalogResponse.push({
                        code: dataCuration?.data?.meta?.code,
                        name: dataCuration?.data?.meta?.name,
                        destinationLink: dataCuration?.data?.meta?.destination_link,
                        displayLayout: dataCuration?.data?.meta?.display_layout,
                        data: dataCuration?.data?.data
                    });
                } catch (e) {
                    console.log(e);
                }
            }));

            const listHomepageCatalog = [];
            for (const x of listHomePageCuration) {
                const picked = tempFullCatalogResponse.find((o: { code: string; }) => o.code === x?.code);
                listHomepageCatalog.push(picked)
            }

            this.setState({
                listHomepageCatalog: listHomepageCatalog,
                listHomepageCuration: listHomePageCuration,
                fetching: false,
            });
        } catch (e) {
            this.setState({
                fetching: false,
                listHomepageCatalog: [],
                listHomepageCuration: [],
            })
        }
    }

    onAreaChange = async (data: any) => {
        this.cookies.set('_amc-a', data.city_group);
        await this.promisedSetState({
            areaCode: data.city_group,
        });
        await this.fetchCityCuration();
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <PageHeader onAreaChangeProps={this.onAreaChange} areaCode={this.state.areaCode} isDesktop={this.state.isDesktop}/>
                <HomeBanner
                    isDesktop={this.state.isDesktop}
                    imageCode={`img-1`}
                    backgroundImage={`https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/chery/home/banner/tigobanner.webp`}
                    backgroundAttachment={(this.state.isDesktop) ? `fixed` : ""}
                />

                <Row style={this.state.isDesktop ? {paddingTop: 10, paddingBottom: 80} : {marginTop: -120}}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <div style={this.state.fetching ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                            <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                        </div>
                        <Row className={`p-20`} style={!this.state.fetching ? {} : {display: 'none'}}>
                            {this.state.listHomepageCatalog.map((listCatalogData: any, x: number) =>
                                <React.Fragment key={x}>
                                    <div className={`chosen-product pb-20`}>
                                        <span className={`chosen-product-text ${(this.state.isDesktop) ? "font-140" : ""}`}>{listCatalogData?.name}</span>
                                        {listCatalogData?.destinationLink === 'variant' &&
                                            <Link to={`/products/${listCatalogData.code}`}><span className={`chosen-product-other`}> Lihat Semua <DoubleRightOutlined/></span></Link>
                                        }
                                    </div>

                                    {listCatalogData?.data.map((item: any, i: number) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <Col className={`p-10`} xxl={{span: 6}} xl={{span: 6}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                                    <CardProduct data={item}/>
                                                </Col>
                                            </React.Fragment>
                                        );
                                    })}
                                    <Divider/>
                                </React.Fragment>
                            )}
                        </Row>

                        <div style={!this.state.fetching && this.state.listHomepageCuration?.length < 1 ? {textAlign: "center"} : {display: 'none'}}>
                            <Empty/>
                        </div>
                    </Col>
                </Row>

                <div style={this.state.isDesktop ? {} : {display: 'none'}}>
                    <HomeBanner
                        imageCode={`img-2`}
                        backgroundImage={`https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/${appCompanyCode}/home/banner/${appCompanyCode}_banner2-1.jpeg`}
                        backgroundAttachment={(this.state.isDesktop) ? `fixed` : ""}
                    />
                </div>

                <Row style={this.state.isDesktop ? {paddingTop: 10, paddingBottom: 80} : {marginTop: -120}}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row style={{paddingTop: 50, paddingBottom: 50}}>
                            <Col xl={{span: 10}} lg={{span: 10}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                <FooterReasonBuy/>
                            </Col>
                            <Col xl={{span: 14}} lg={{span: 14}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                <Testimonial/>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <FooterSection/>
            </React.Fragment>
        );
    }
}

export default withRouter(Home);
