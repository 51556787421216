import Cookies from "universal-cookie"
import axios from "axios"
import {baseUrlTrack, xApiKeyTrack} from "../../config/apiConfig/apiConfig"
import {Buffer} from "buffer"

class TrackLogServices {
    private cookies = new Cookies()
    private axios = axios.create({
        baseURL: baseUrlTrack,
    })

    public async sendTrackLog(params?: any) {
        console.log("LOG")
        // await navigator.geolocation.getCurrentPosition(
        //     async position => {
        //         return await this.sendLog({
        //             params: params,
        //             location_latitude: position.coords.latitude,
        //             location_longitude: position.coords.longitude,
        //         })
        //     },
        //     async err => {
        //         return await this.sendLog({
        //             params: params,
        //             location_latitude: null,
        //             location_longitude: null,
        //         })
        //     }
        // )
    }

    sendLog = async (params: any) => {
        const cookies = new Cookies()

        // handle ideal cookies
        let idealCookies = cookies.get('_ideal') ?? null
        if (!idealCookies) {
            // const urlParts = url.parse(params?.params?.eventSourceUrl, true)
            // idealCookies = urlParts?.query?.clid ?? null
            // if (idealCookies) {
            //     await this.cookies.set('_ideal', idealCookies, {maxAge: 604800})
            // }
        }

        const dataLog = {
            event_name: params?.params?.eventName,
            event_source_url: params?.params?.eventSourceUrl,
            action_source: "website",
            user_data: {
                phone: null,
                email: null,
                client_ip_address: null,
                client_user_agent: null,
                cookies: {
                    id: idealCookies,
                    ga: cookies.get('_ga') ?? null,
                    fbp: cookies.get('_fbp') ?? null,
                    fbc: cookies.get('_fbc') ?? null,
                    here: cookies.get('_here') ?? null
                }
            },
            location: {
                location_latitude: params?.location_latitude,
                location_longitude: params?.location_longitude,
                area: null,
                city_code: null,
                city_name: null
            },
            custom_data: params?.params?.customData
        }

        try {
            const log = await this.axios.post<any>('/log/amartahonda.com',
                dataLog, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyTrack
                    }
                })
            if (!cookies.get('_here')) {
                await this.cookies.set('_here', Buffer.from(JSON.stringify(log?.data?.data)).toString('base64'), {
                    maxAge: 604800
                })
            }

            return log
        } catch (e) {
            console.log(e)
            return []
            // throw new Error(e.response.data.error.message)
        }
    }

    public async getReverseLocation(params?: any) {
        navigator.geolocation.getCurrentPosition(
            async position => {
                return await this.getHereApi({
                    params: params,
                    location_latitude: position.coords.latitude,
                    location_longitude: position.coords.longitude,
                })
            },
            async err => {
                return await this.getHereApi({
                    params: params,
                    location_latitude: null,
                    location_longitude: null,
                })
            }
        )
    }

    getHereApi = async (params: any) => {
        const cookies = new Cookies()
        const latitude = params.location_latitude
        const longitude = params.location_longitude
        const hereCookies = cookies?.get('_here')
        if (!hereCookies) {
            if (latitude && longitude) {
                try {
                    const hereResponse = await this.axios.get<any>(`/reverse-geocode?lat=${latitude}&lng=${longitude}`, {
                        headers: {"X-Api-Key": xApiKeyTrack,}
                    })

                    this.cookies.set('_here', Buffer.from(JSON.stringify(hereResponse?.data?.data)).toString('base64'), {maxAge: 604800})

                    return hereResponse?.data?.data
                } catch (e: any) {
                    throw new Error(e?.response?.data?.error?.message)
                }
            }
        }
    }
}

export const trackLogServices = new TrackLogServices()